import Toast from './Toast-item'
import { ToastMessage } from '../../hooks/useToast'

interface ToastContainerProps {
  messages: ToastMessage[]
}

const ToastConatiner = ({ messages }: ToastContainerProps) => {
  return (
    <div className="toast">
      {messages.map((item) => (
        <Toast key={item.id} message={item} />
      ))}
    </div>
  )
}

export default ToastConatiner
