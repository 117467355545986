import { useAuth } from '../../hooks/useAuth'
import Menu from '../menu'

interface PropsBase {
  children: React.ReactElement
}

export default function Base({ children }: PropsBase) {
  const { loading } = useAuth()

  return (
    <main>
      {/* {loading && <div className="loading">{loading}</div>} */}
      <Menu />
      {children}
    </main>
  )
}
