import { Link } from 'react-router-dom'

import { FiShoppingCart, FiHome, FiInfo } from 'react-icons/fi'
import { IoMdLogOut } from 'react-icons/io'

import './style.scss'
import { useAuth } from '../../hooks/useAuth'

export default function Menu() {
  const { signOut } = useAuth()
  return (
    <nav className="main-menu">
      <ul>
        <li>
          <Link to="/">
            <FiHome size={24} />
            <span className="nav-text">Vender</span>
          </Link>
        </li>
        <li>
          <Link to="/booking">
            <FiShoppingCart size={24} />
            <span className="nav-text">Emissão</span>
          </Link>
        </li>
        <li>
          <Link to="/about">
            <FiInfo size={24} />
            <span className="nav-text">Sobre</span>
          </Link>
        </li>
        <li>
          <a href="/" onClick={signOut}>
            <IoMdLogOut size={24} />
            <span className="nav-text">Sair</span>
          </a>
        </li>
      </ul>
      {/* 
    <ul className="logout">
      <li>
        <a>
          <i className="fa fa-cogs nav-icon"></i>
          <span className="nav-text">Settings</span>
        </a>
      </li>

      <li>
        <a>
          <i className="fa fa-right-from-bracket nav-icon"></i>
          <span className="nav-text">Logout</span>
        </a>
      </li>
    </ul> */}
    </nav>
  )
}
