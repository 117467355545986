import { Link } from "react-router-dom";

export function About () {
  return (
    <div>
      <h2>About</h2>
      <Link to="/">...voltar</Link>
    </div>
  )
}
