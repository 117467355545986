import { Link } from 'react-router-dom'
import { FiPlus, FiMinus } from 'react-icons/fi'
import { ItemGroup } from '../../@nanpos/plughub-react'
import './style.scss'
import { useBookingCartContext } from '../../@nanpos/plughub-react/BookingCartContext'
import TextField from '../Elements/TextField'

interface PropsAction {
  item: ItemGroup
}

export default function Action({ item }: PropsAction) {
  const itemKey = `${item.repository}__${item.grouper}__${item.id}`

  const {
    cartItensQTDE,
    addToCart,
    removeFromCart,
    items,
  } = useBookingCartContext()

  return (
    <div className="item">
      {cartItensQTDE[itemKey] > 0 && (
        <div>
          <button
            onClick={() => {
              removeFromCart(item, item.metadata.slug, item.metadata.code)
            }}
          >
            <FiMinus size={26} />
          </button>
        </div>
      )}
      {/* <div className="textinput">
        <TextField value={cartItensQTDE[itemKey]} />
      </div> */}

      <button
        onClick={() => {
          addToCart(item, item.metadata.slug, item.metadata.code)
        }}
      >
        <FiPlus size={26} />
      </button>
    </div>
  )
}
