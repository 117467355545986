import React from 'react'
import { useItensGroups } from './hooks'
import {
  PlugHubContext,
  PlugHubContextProps,
  usePlugHubContext,
} from './PlugHubContext'

export interface ItemGroup {
  readonly repository: string
  readonly id: string
  readonly grouper: string
  readonly parent?: string
  readonly isParent: boolean
  readonly itensCount: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly metadata: any
}

export interface ItensGroupsResult {
  readonly context: PlugHubContextProps
  readonly itensGroups: ItemGroup[]
  readonly loading: boolean
  readonly error?: Error
}

interface ItensGroupsProps {
  repository: string
  repoType?: string
  grouper?: string
  groupExact?: string
  parent?: string
  listAll?: boolean
  subject?: string
  renderComponent: (props: ItensGroupsResult) => React.ReactNode
}

export const ItensGroups: React.FC<ItensGroupsProps> = (props) => {
  const context = usePlugHubContext()
  const { itensGroups, loading, error } = useItensGroups(
    context.tenant,
    props.repository,
    props.grouper,
    props.groupExact,
    props.parent,
    props.listAll,
    props.subject,
  )

  if (error)
    return (
      <div>
        <pre>{error.message}</pre>
      </div>
    )
  if (error) return <div>failed to load</div>
  // if (loading) return <div>Carregando...</div>

  return (
    <PlugHubContext.Consumer>
      {(context) => {
        const childrenProps: ItensGroupsResult = {
          context,
          itensGroups,
          loading,
          error,
        }
        return props.renderComponent
          ? props.renderComponent(childrenProps)
          : null
      }}
    </PlugHubContext.Consumer>
  )
}
