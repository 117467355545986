import React from 'react'
import { SWRConfig } from 'swr'
import { fetcherFunction } from './apiclient'
import { BookingCartProvider } from './BookingCartContext'
// import { BookingCartProvider } from './BookingCartContext'

export interface PlugHubContextProps {
  tenant: string
  bookingChannel: string
  accessToken?: string
}

export const PlugHubContext = React.createContext<PlugHubContextProps>({
  tenant: 'undefined',
  bookingChannel: 'default',
})

export const usePlugHubContext = () => React.useContext(PlugHubContext)

export interface PlugHubProviderProps {
  tenant: string
  bookingChannel: string
  accessToken?: string
  children: React.ReactChild
}

export const PlugHubProvider = (props: PlugHubProviderProps) => {
  return (
    <PlugHubContext.Provider
      value={{
        tenant: props.tenant,
        bookingChannel: props.bookingChannel,
        accessToken: props.accessToken,
      }}
    >
      <SWRConfig
        value={{
          // revalidateIfStale: false,
          // revalidateOnFocus: false,
          // revalidateOnReconnect: false,
          // refreshInterval: 3000000000000,
          fetcher: fetcherFunction,
        }}
      >
        <BookingCartProvider> {props.children}</BookingCartProvider>
      </SWRConfig>
    </PlugHubContext.Provider>
  )
}
