import { createBrowserRouter } from 'react-router-dom'
import * as Pages from './pages'
import { PrivateRoute } from './utils/PrivateRoute'

export default createBrowserRouter([
  {
    path: '/login',
    element: <Pages.LoginPage />,
  },
  {
    path: '/',
    element: (
      <PrivateRoute>
        <Pages.Home />
      </PrivateRoute>
    ),
  },
  {
    path: '/customers/:id',
    element: (
      <PrivateRoute>
        <Pages.Customers />
      </PrivateRoute>
    ),
  },
  {
    path: '/list',
    element: (
      <PrivateRoute>
        <Pages.List />
      </PrivateRoute>
    ),
  },
  {
    path: '/booking',
    element: (
      <PrivateRoute>
        <Pages.Booking />
      </PrivateRoute>
    ),
  },
  {
    path: '/booking/:bookingId',
    element: (
      <PrivateRoute>
        <Pages.Booking />
      </PrivateRoute>
    ),
  },
  {
    path: '/booking/:bookingId/print',
    element: (
      <PrivateRoute>
        <Pages.BookingPrint />
      </PrivateRoute>
    ),
  },
  {
    path: '/:eventId',
    element: (
      <PrivateRoute>
        <Pages.Event />
      </PrivateRoute>
    ),
  },
  {
    path: '/:eventId/:sectorId',
    element: <Pages.SubSectors />,
  },
  {
    path: '/:eventId/:sectorId/:subsectorId',
    element: <Pages.Seats />,
  },
  {
    path: '/about',
    element: <Pages.About />,
  },
])
