import { Link, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { RepoItensList } from '../@nanpos/plughub-react'
import { GoChevronLeft } from 'react-icons/go'
import BasePrint from '../components/print/index'
import Cart from '../components/cart'
import { instance } from '../@nanpos/plughub-react/apiclient'
import QRCode from 'react-qr-code'
import { formateReal, formatterDate } from '../utils/formatter'

export function BookingPrint() {
  const { bookingId } = useParams()

  const [data, setData] = useState<any>()

  const today = new Date(Date.now())

  useEffect(() => {
    bookingId && getData()
    return () => {
      setData([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingId])

  const getData = async () => {
    const auth = localStorage.getItem('user_cdigital_pdv') as string
    const tenant = process.env.REACT_APP_IS_DEVELOP ? "cdigital-develop" : "cdigital"

    const url = `/Booking/GetBookingOrder?BookingId=${bookingId}&Tenant=${tenant}`
    await instance
      .get(url, {
        headers: {
          Authorization: `Bearer ${JSON.parse(auth).access}`,
        },
      })
      .then((response) => {
        console.log(response.data)

        setData(response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <BasePrint>
      {data?.bookingItems?.map((item: any, i: number) => (
        <section className="sheet" key={i}>
          <div className="sector">
            {item.sector.name}
            <div className="details">
              <span>Bloco: {item.subsector.name}</span>
              <span>Portão: {item.gate.name}</span>
              <span>Fileira: {item.seat.row}</span>
              <span>Cadeira: {item.seat.number}</span>
            </div>
          </div>
          <article>
            <div className="code">
              <div className="item">#{bookingId}</div>
              <div className="id">{item.id}</div>
              <QRCode value={item?.barcode} size={70} />
              <div className="small">{item?.barcode}</div>
            </div>
            <div className="content">
              <div className="item upper">
                <div>{item.ticketType.name}</div>
                <div className="right">{formateReal(item.amount)}</div>
              </div>
              <div className="user">
                {item.attendee.name.substring(0, 40)}
                <p>{item.attendee.document}</p>
              </div>
              <div className="item medium">
                <div>{item.event.name}</div>
              </div>
              <div className="item small">
                <div>{item.event.tournament}</div>
                <div className="right">
                  {formatterDate(item.event.startsAt)}
                </div>
              </div>
              <div className="item small"> Local: {item.event.venue.name}</div>
              <div className="thin">
                {item.event.insurancePolicy.company}{' '}
                {item.event.insurancePolicy.policy}
                <div>
                  {item.event.venue.documents.alvara.nome}:
                  {item.event.venue.documents.alvara.valor} v.
                  {item.event.venue.documents.alvara.validade} -
                  {item.event.venue.documents.avcb.nome}:
                  {item.event.venue.documents.avcb.valor} - v
                  {item.event.venue.documents.avcb.validade}{' '}
                </div>
              </div>
            </div>
          </article>
          <div className="footer">
            Emitido em {formatterDate(today.toISOString())}
          </div>
        </section>
      ))}
    </BasePrint>
  )
}
