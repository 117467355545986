import useSWR from 'swr'
import type { ItemGroup } from '../ItensGroups'
import { fetcherItensGroups } from '../apiclient'

export const useItensGroups = (
  tenant: string,
  repository: string,
  grouper?: string,
  groupExact?: string,
  parent?: string,
  listAll?: boolean,
  subject?: string
) => {
  const { data, error } = useSWR(
    [
      `/Itens/ListItensGroups`,
      tenant,
      repository,
      grouper,
      groupExact,
      parent,
      listAll,
      subject
    ],
    fetcherItensGroups
  )
  const itensGroups = data?.data?.itensGroups as ItemGroup[]
  const returnObj = {
    itensGroups: itensGroups,
    count: itensGroups?.length,
    loading: !error && !data,
    error: error
  }
  return returnObj
}
