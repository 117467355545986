import { ItensGroups } from '../@nanpos/plughub-react'
import Base from '../components/base'
import { GoChevronLeft } from 'react-icons/go'
import Cart from '../components/cart'
import { Link, useParams } from 'react-router-dom'
import Action from '../components/Action'
import { useBookingCartContext } from '../@nanpos/plughub-react/BookingCartContext'

export function SubSectors() {
  const params = useParams()
  const { owner } = useBookingCartContext()
  const [id] = owner.split('__')
  return (
    <Base>
      <div className="container">
        <div className="content">
          <div className="header">
            <Link to={`/${params.eventId}`}>
              <div className="icon">
                <GoChevronLeft size={34} />
              </div>
            </Link>
            <h2>
              {params.eventId}
              <span>/ {params.sectorId}</span>
            </h2>
          </div>

          <div className="scroll">
            <ItensGroups
              repository={`${params.eventId}`}
              grouper="subsector"
              parent={params.sectorId}
              subject={id}
              renderComponent={(props) => {
                return (
                  <>
                    <h3>SubSetores</h3>
                    <ol className="list">
                      {props.itensGroups?.map((item) => (
                        <li>
                          <Link
                            to={`/${params.eventId}/${params.sectorId}/${item.id}`}
                          >
                            <span>{item.id}</span>
                          </Link>
                          <div className="action">
                            <Link
                              to={`/${params.eventId}/${params.sectorId}/${item.id}`}
                              className="link"
                            >
                              Cadeiras
                            </Link>
                            <Action item={item} />
                          </div>
                        </li>
                      ))}
                    </ol>
                  </>
                )
              }}
            />
          </div>
        </div>
        <Cart />
      </div>
    </Base>
  )
}
