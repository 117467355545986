import React from 'react'
import { useRepoItens } from './hooks'
import { PlugHubContext, usePlugHubContext } from './PlugHubContext'

export type SeatSector = {
  name: string
  slug: string
  code: number
}

export type SeatSubSector = {
  name: string
  slug: string
  code: number
}

export type SeatType = {
  name: string
  slug: string
}

export type Seat = {
  id: number
  rowNumber: string
  sector: SeatSector
  subsector: SeatSector
  seatType: SeatType
}

export interface RepositoryItem<D> {
  readonly repository: string
  readonly id: string
  readonly bookingStatus: string
  readonly groups: string
  readonly metadata: D
}

export interface RepositoryItensList {
  readonly context: string
  readonly items: RepositoryItem<Seat>[]
  readonly loading: boolean
  readonly error?: Error
}

interface RepoItensListProps {
  repository: string
  grouper: string
  group: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderComponent: (props: RepositoryItensList) => React.ReactNode
}

export const RepoItensList: React.FC<RepoItensListProps> = (props) => {
  const context = usePlugHubContext()
  const { items, loading, error } = useRepoItens(
    context.tenant,
    props.repository,
    props.grouper,
    props.group
  )

  if (error) return <div>failed to load</div>
  if (loading) return <div>loading...</div>

  return (
    <PlugHubContext.Consumer>
      {(context) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const childrenProps: any = {
          context,
          items,
          loading,
          error
        }
        return props.renderComponent
          ? props.renderComponent(childrenProps)
          : null
      }}
    </PlugHubContext.Consumer>
  )
}
