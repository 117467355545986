/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios'
import { useAuth } from '../../../hooks/useAuth'
import { useToast } from '../../../hooks/useToast'

export const instance = axios.create({
  baseURL: `${process.env.REACT_APP_EVENTHUB_API}`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})


instance.interceptors.request.use(
  function (config) {
    const data = localStorage.getItem('user_cdigital_pdv')
    if (data) {
      const auth = JSON.parse(data)
      config.headers['Authorization'] = 'Bearer ' + auth.access
    }
    return config
  },
  function (error) {
    const { signOut } = useAuth()
    const { addToast } = useToast()
    if (error.response.status === 401) {
      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Ocorreu um erro na sua autorização.'
      })
      signOut()
      // } else if (error.response.status == 404) {
      //   return []
    } else {
      throw new Error(error)
    }
  }
)


export const fetcherRepoGroupers = (args: any[]) => {
  const resource = args[0]
  const params = {
    ListAll: args[1],
    Parent: args[2],
    Grouper: args[3]
  }
  return instance.get(resource, {
    params
  })
}

export const fetcherRepoGroups = (args: any[]) => {
  const resource = args[0]
  const params = {
    Tenant: args[1],
    Grouper: args[2],
    ListAll: args[3],
    Parent: args[4]
  }
  return instance.get(resource, {
    params
  })
}

export const fetcherItensGroupers = (args: any[]) => {
  const resource = args[0]
  const params = {
    Tenant: args[1],
    Repository: args[2],
    Parent: args[3],
    Grouper: args[4]
  }
  return instance.get(resource, {
    params
  })
}

export const fetcherItensGroups = (args: any[]) => {
  const resource = args[0]
  const params = {
    Tenant: args[1],
    Repository: args[2],
    Grouper: args[3],
    GroupExact: args[4],
    Parent: args[5],
    ListAll: args[6],
    Subject: args[7]
  }
  return instance.get(resource, {
    params
  })
}

export const fetcherItens = (args: any[]) => {
  const resource = args[0]
  const params = {
    Tenant: args[1],
    Repository: args[2],
    Grouper: args[3],
    Group: args[4]
  }
  return instance.get(resource, {
    params
  })
}

export const fetcherRepositories = (args: any[]) => {
  const resource = args[0]
  const params = {
    Tenant: args[1],
    RepoType: args[2],
    Grouper: args[3],
    Group: args[4],
    Subject: args[5]
  }
  return instance.get(resource, {
    params
  })
}

export const fetcherFunction = (resource: string) => {
  return instance.get(resource)
}
