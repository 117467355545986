import { Link } from 'react-router-dom'
import { GoHome } from 'react-icons/go'

import { useBookingCartContext } from '../../@nanpos/plughub-react/BookingCartContext'
import { formateReal } from '../../utils/formatter'
import Button from '../Elements/Button'
import { FiTrash } from 'react-icons/fi'
import { useState } from 'react'

import './style.scss'

interface PropsItem {
  properts: string
  value: string
}

export default function Item({ properts, value }: PropsItem) {
  const [repository, grouper, group, slug, code, qtde, amount] = value.split(
    '__',
  )
  const {
    cartItensQTDE,
    total,
    removeFromCart,
    clearCart,
    makeReservation,
    items,
  } = useBookingCartContext()
  const [error, setError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const author = 'autor'

  const remove = () => {
    const itenGroup = `${repository}__${grouper}__${group}`

    const data = {
      repository,
      grouper,
      metadata: { amountBase: +amount },
      id: +code,
    } as any

    clearCart()
  }

  return (
    <article>
      <div className="header">
        <div>
          <p>{repository}</p>
          <p>{slug}</p>
        </div>
        <div className="icon" onClick={remove}>
          <FiTrash />
        </div>
      </div>

      <div className="footer">
        <span>{+qtde}</span>
        <span>{formateReal(+qtde * +amount)}</span>
      </div>
    </article>
  )
}
