import { useEffect, useState } from 'react'
import { FiX } from 'react-icons/fi'
import { ToastMessage, useToast } from '../../../hooks/useToast'

interface ToastProps {
  message: ToastMessage
}

const Toast = ({ message }: ToastProps) => {
  const { removeToast } = useToast()

  const [active, setActive] = useState('')

  useEffect(() => {
    setActive('active')

    const timer = setTimeout(() => {
      setActive('')
      setTimeout(() => {
        removeToast(message.id)
      }, 500)
    }, 5000)

    return () => {
      clearTimeout(timer)
    }
  }, [message.id, removeToast])

  return (
    <div className={`toast-content ${message.type} ${active}`}>
      <div>
        <h4>{message.title}</h4>
        {message.description && <p>{message.description}</p>}
      </div>
      <button type="button" onClick={() => removeToast(message.id)}>
        <FiX size={22} />
      </button>
    </div>
  )
}

export default Toast
