import { useState } from 'react'
import { Loader } from '../Loader'
import './style.scss'

export type ButtonProps = {
  disabled?: boolean
  loading?: boolean
  size?: 'small' | 'medium' | 'large'
  children?: JSX.Element | string
  icon?: JSX.Element
  type?: 'button' | 'submit' | 'reset'
  onMouseDown?: () => void
  onClick?: () => void
}
const Button = ({
  children,
  icon,
  disabled,
  loading = false,
  type = 'button',
  ...props
}: ButtonProps) => {
  const [click, setClick] = useState(false)

  return (
    <button
      type={type}
      className={click ? 'focused button' : 'button'}
      onMouseDown={() => setClick(true)}
      onAnimationEnd={() => setClick(false)}
      disabled={disabled}
      {...props}
    >
      {icon}
      { loading ? (
        <>
          <Loader size={20} />
        </>
      ) : (
        <>
          {!!children && <span>{children}</span>}
        </>
      )}
    </button>
  )
}

export default Button
