import React, { useCallback, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import ToastConatiner from '../components/Toast'

export interface ToastMessage {
  id: string
  type?: 'success' | 'error' | 'info' | 'warning'
  title: string
  description?: string
}

interface ToastContextData {
  addToast(message: Omit<ToastMessage, 'id'>): void
  removeToast(id: string): void
}

interface ToastContextProvider {
  children: React.ReactNode
}

const ToastContext = React.createContext<ToastContextData>(
  {} as ToastContextData,
)

export function ToastProvider({ children }: ToastContextProvider) {
  const [messages, setMessages] = useState<ToastMessage[]>([])

  const addToast = useCallback(
    ({ type, title, description }: Omit<ToastMessage, 'id'>) => {
      const id = uuidv4()

      const toast = {
        id,
        type,
        title,
        description,
      }

      setMessages((state) => [...state, toast])
    },
    [],
  )

  const removeToast = useCallback((id: string) => {
    setMessages((state) => state.filter((message) => message.id !== id))
  }, [])

  return (
    <ToastContext.Provider
      value={{
        addToast,
        removeToast,
      }}
    >
      {children}
      <ToastConatiner messages={messages} />
    </ToastContext.Provider>
  )
}

export function useToast() {
  return React.useContext(ToastContext)
}
