import React from 'react'
import { useRepositories } from './hooks'
import { PlugHubContext, usePlugHubContext } from './PlugHubContext'

export interface Repository<MetadataType> {
  readonly id: string
  readonly foreignId?: string
  readonly repoType: string
  readonly metadata: MetadataType
}

export interface RepositoryList<MetadataType> {
  readonly context: string
  readonly repositories: Repository<MetadataType>[]
  readonly loading: boolean
  readonly error?: Error
  readonly day?: string
}

interface RepoListPropsBase {
  repoType: string
  grouper: string
  group: string
  listAll?: boolean
  subject?: string
}

interface RepoListPropsWithAs extends RepoListPropsBase {
  as: React.ElementType
  renderComponent?: never
}

interface RepoListPropsWithRenderComponent extends RepoListPropsBase {
  as?: never
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderComponent: (props: RepositoryList<any>) => React.ReactNode
}

type RepoListProps = RepoListPropsWithAs | RepoListPropsWithRenderComponent

export const RepoList: React.FC<RepoListProps> = (props) => {
  const context = usePlugHubContext()
  const { repositories, loading, error } = useRepositories(
    context.tenant,
    props.repoType,
    props.grouper,
    props.group,
    props.subject,
  )

  const day = props.group

  if (error) return <div>failed to load: {error.message}</div>
  if (loading) return <div className="content">carregando...</div>

  return (
    <PlugHubContext.Consumer>
      {(context) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const childrenProps: any = {
          context,
          repositories,
          loading,
          error,
        }

        if (props.as) {
          const Component = props.as
          return <Component {...childrenProps} day={day} />
        }
        return props.renderComponent(childrenProps)
      }}
    </PlugHubContext.Consumer>
  )
}
