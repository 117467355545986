/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'
import { ItemGroup } from './ItensGroups'
import { usePlugHubContext } from './PlugHubContext'
import { AxiosResponse } from 'axios'
import { instance } from './apiclient'

interface BookingCart {
  status: 'RESERVED' | 'CONFIRMED'
  cartItensQTDE: ItensGeneric
  amount: ItensGeneric
  items: ItensGenericString
  total: number
  totalQtde: number
  makeReservation: (author?: string) => Promise<AxiosResponse>
  clearCart: () => void
  addToCart: (itemGroup: ItemGroup, name: string, date: string) => void
  addToCartUnique: (itemGroup: ItemGroup, name: string, date: string) => void
  removeFromCart: (
    itemGroup: ItemGroup,
    name: string,
    date: string,
    all?: boolean,
  ) => void
  addOwner: (owner: string) => void
  owner: string
}

type BookingCartContext = BookingCart

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BookingCartContext = React.createContext<BookingCartContext>(
  {} as BookingCartContext,
)

export const useBookingCartContext = () => React.useContext(BookingCartContext)

export interface BookingCartProviderProps {
  children: React.ReactNode
}

interface ReservationItem {
  repository: string
  grouper: string
  group: string
  quantity: number
  strategy: 'default' | 'Site'
}

interface ItensGeneric {
  [key: string]: number
}

interface ItensGenericString {
  [key: string]: string
}

export const BookingCartProvider = (props: BookingCartProviderProps) => {
  const context = usePlugHubContext()
  const [items, setItems] = useState<ItensGenericString>({})
  const [cartItensQTDE, setCartItensQTDE] = useState<ItensGeneric>({})
  const [amount, setAmount] = useState<ItensGeneric>({})
  const [total, setTotal] = useState<number>(0)
  const [totalQtde, setTotalQtde] = useState<number>(0)
  const [owner, setOwner] = useState<string>('')

  const makeReservation = React.useCallback(() => {
    const cartItens: ReservationItem[] = []

    Object.entries(cartItensQTDE).forEach(([key, quantity], index) => {
      const [repository, grouper, group] = key.split('__')

      cartItens.push({
        repository,
        grouper,
        group,
        quantity,
        strategy: 'default',
      })
    })

    const [id] = owner.split('__')

    const payload = {
      tenant: context.tenant, //guid
      owner: id,
      itens: cartItens,
    }

    return instance.post('/Booking/MakeReservation', payload)
  }, [cartItensQTDE, context.tenant, owner])

  React.useEffect(() => {
    const newTotal = Object.values(amount).reduce(
      (prevVal, elem) => prevVal + elem,
      0,
    )
    setTotal(newTotal)
  }, [amount])

  React.useEffect(() => {
    const newTotalQtde = Object.values(cartItensQTDE).reduce(
      (prevVal, elem) => prevVal + elem,
      0,
    )
    setTotalQtde(newTotalQtde)
  }, [cartItensQTDE])

  const addToCart = React.useCallback(
    (
      itemGroup: {
        repository: any
        grouper: any
        id: any
        metadata: { amountBase: number }
      },
      name: any,
      date: any,
    ) => {
      const key = `${itemGroup.repository}__${itemGroup.grouper}__${itemGroup.id}`

      setCartItensQTDE({
        ...cartItensQTDE,
        [key]: key in cartItensQTDE ? cartItensQTDE[key] + 1 : 1,
      })

      setAmount({
        ...amount,
        [key]:
          key in amount
            ? amount[key] + itemGroup.metadata.amountBase
            : itemGroup.metadata.amountBase,
      })

      setItems({
        ...items,
        [key]:
          key in items
            ? `${itemGroup.repository}__${itemGroup.grouper}__${
                itemGroup.id
              }__${name}__${date}__${cartItensQTDE[key] + 1}__${
                itemGroup.metadata.amountBase
              }`
            : `${itemGroup.repository}__${itemGroup.grouper}__${
                itemGroup.id
              }__${name}__${date}__${1}__${itemGroup.metadata.amountBase}`,
      })
    },
    [cartItensQTDE, amount, items],
  )

  const addToCartUnique = React.useCallback(
    (
      itemGroup: {
        repository: any
        grouper: any
        id: any
        metadata: { precoUnitario: number; tipoIngressoNome: any }
      },
      name: any,
      date: any,
    ) => {
      const key = `${itemGroup.repository}__${itemGroup.grouper}__${itemGroup.id}`
      // if (key in cartItensQTDE && cartItensQTDE[key] == itemGroup.itensCount) {
      //   return
      // }

      setCartItensQTDE({
        [key]: key in cartItensQTDE ? cartItensQTDE[key] + 1 : 1,
      })

      setAmount({
        [key]:
          key in amount
            ? amount[key] + itemGroup.metadata.precoUnitario
            : itemGroup.metadata.precoUnitario,
      })

      setItems({
        [key]:
          key in items
            ? `${name}__${date}__${itemGroup.metadata.tipoIngressoNome}__${
                cartItensQTDE[key] + 1
              }__${itemGroup.metadata.precoUnitario}`
            : `${name}__${date}__${
                itemGroup.metadata.tipoIngressoNome
              }__${1}__${itemGroup.metadata.precoUnitario}`,
      })
    },
    [cartItensQTDE, amount, items],
  )

  const removeFromCart = React.useCallback(
    (
      itemGroup: {
        repository: any
        grouper: any
        id: any
        metadata: { amountBase: number }
      },
      name: any,
      date: any,
      all = false,
    ) => {
      const key = `${itemGroup.repository}__${itemGroup.grouper}__${itemGroup.id}`
      if (all) {
        const { [key]: remove, ...rest } = items
        setItems(rest)

        return
      }

      if (key in cartItensQTDE && cartItensQTDE[key] === 0) return

      setAmount({
        ...amount,
        [key]:
          key in amount
            ? amount[key] - itemGroup.metadata.amountBase
            : itemGroup.metadata.amountBase,
      })
      const { [key]: _, ...restCartItens } = cartItensQTDE
      const { [key]: __, ...rest } = items

      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      cartItensQTDE[key] === 1
        ? (setItems(rest), setCartItensQTDE(restCartItens))
        : (setItems({
            ...items,
            [key]: `${itemGroup.repository}__${itemGroup.grouper}__${
              itemGroup.id
            }__${name}__${date}__${cartItensQTDE[key] - 1}__${
              itemGroup.metadata.amountBase
            }`,
          }),
          setCartItensQTDE({
            ...cartItensQTDE,
            [key]: key in cartItensQTDE ? cartItensQTDE[key] - 1 : 1,
          }))
    },
    [amount, cartItensQTDE, items],
  )

  const addOwner = React.useCallback((owner: string) => {
    setOwner(owner)
  }, [])

  const clearCart = React.useCallback(() => {
    setCartItensQTDE({})
    setItems({})
    setTotalQtde(0)
    setTotal(0)
    setAmount({})
  }, [])

  return (
    <BookingCartContext.Provider
      value={{
        status: 'RESERVED',
        cartItensQTDE,
        amount,
        totalQtde,
        total,
        items,
        makeReservation,
        clearCart,
        addToCart,
        addToCartUnique,
        removeFromCart,
        addOwner,
        owner,
      }}
    >
      {props.children}
    </BookingCartContext.Provider>
  )
}
