import useSWR from 'swr'
import type { RepositoryItem } from '../RepoItensList'
import type { Seat } from '../RepoItensList'
import { fetcherItens } from '../apiclient'

export const useRepoItens = (
  tenant: string,
  repository: string,
  grouper?: string,
  group?: string,
) => {
  const { data, error } = useSWR(
    [
      `/Itens/ListItens`,
      tenant,
      repository,
      grouper,
      group,
    ],
    fetcherItens
  )
  const returnObj = {
    items: data?.data?.items as RepositoryItem<Seat>,
    loading: !error && !data,
    error: error
  }
  return returnObj
}
