import React, { Dispatch, useState } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../../hooks/useAuth'
import Button from '../Elements/Button'
import TextField from '../Elements/TextField'

import { Loader } from '../Elements/Loader'
import axios from 'axios'

interface PropsSignUp {
  document: string
  handleSearch: () => Promise<void>
  setValue: Dispatch<React.SetStateAction<string>>
}

export default function SignUp({
  document,
  handleSearch,
  setValue,
}: PropsSignUp) {
  const [loading, setLoading] = useState<boolean>(false)
  const { formError } = useAuth()

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()

    const formData = new FormData(event.currentTarget)
    const displayName = formData.get('displayName')
    const documentTypeCpf = formData.get('documentTypeCpf')

    onSignUp(`${displayName}`, `${documentTypeCpf}`)
  }

  const onSignUp = React.useCallback(
    (displayName: string, documentTypeCpf: string) => {
      const auth = localStorage.getItem('user_cdigital_pdv') as string

      const data = {
        displayName,
        documentTypeCpf,
      }
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_HOST}/customers/`,
        headers: {
          Authorization: `Bearer ${JSON.parse(auth).access}`,
        },
        data,
      }

      setLoading(true)
      axios
        .request(config)
        .then((response) => {
          setValue(documentTypeCpf)
          setTimeout(() => {
            handleSearch()
          }, 200)
        })
        .catch((error) => {
          console.error('deu ruim no signup:', error)
        })
        .finally(() => setLoading(false))
    },
    [],
  )

  return (
    <div className="wrap">
      <h2>Cadastrar usuário</h2>
      <br />
      <form onSubmit={handleSubmit}>
        <div className="grid">
          {formError && (
            <div className="message message-error">{formError}</div>
          )}
          <TextField
            name="documentTypeCpf"
            placeholder="Documento"
            type="text"
            initialValue={document}
          />
          <div className="s6">
            <TextField name="displayName" placeholder="Nome" type="text" />
          </div>
          <Button type="submit" loading={loading}>
            Cadastrar
          </Button>
        </div>
      </form>
    </div>
  )
}
