import useSWR from 'swr'
import type { Repository } from '../RepoList'
import { fetcherRepositories } from '../apiclient'

export const useRepositories = (
  tenant: string,
  repoType: string,
  grouper: string,
  group: string,
  subject?: string
) => {
  const { data, error } = useSWR(
    [`/Repo/ListRepositories`, tenant, repoType, grouper, group, subject],
    fetcherRepositories
  )
  const returnObj = {
    repositories: data?.data?.repositories as Repository<unknown>[],
    loading: !error && !data,
    error: error
  }
  return returnObj
}
