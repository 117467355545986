import Button from '../Elements/Button'
import TextField from '../Elements/TextField'
import { Loader } from '../Elements/Loader'
import { useEffect, useState } from 'react'
import { useToast } from '../../hooks/useToast'
import { maskCpf } from '../../utils/formatter'
import { instance } from '../../@nanpos/plughub-react/apiclient'

import './style.scss'

interface Props {
  item: any
  bookingId: string
  getData: (id?: string) => Promise<void>
  status: string
}

export default function UpdateTicket({
  item,
  bookingId,
  getData,
  status,
}: Props) {
  const { addToast } = useToast()

  const [loading, setLoading] = useState<boolean>(false)
  const [enable, setEnable] = useState<boolean>(false)
  const [enableSelect, setEnableSelect] = useState<boolean>(false)

  const [values, setValues] = useState({
    attendeeName: item?.attendee?.name,
    attendeeDocument: item?.attendee?.document,
    ticketTypeId: item.ticketType.id,
  })

  const disabled = !values.attendeeName || !values.attendeeDocument

  const edit = status === 'reserved' || status === 'filled'

  useEffect(() => {
    if (item?.allowedTicketTypes?.length === 1) {
      setValues({
        ...values,
        ticketTypeId: item?.allowedTicketTypes[0]?.id,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item])

  const handleChange = (e: string, form: string) => {
    const value = {
      ...values,
      [form]: e,
    }

    setValues(value)
  }

  const handleSubmit = async () => {
    setEnableSelect(false)
    setLoading(true)
    const url = `/Booking/UpdateBookingOrderItem`
    const tenant = process.env.REACT_APP_IS_DEVELOP
      ? 'cdigital-develop'
      : 'cdigital'

    const { ticketTypeId, attendeeName, attendeeDocument } = values
    await instance
      .post(url, {
        tenant: tenant,
        bookingId,
        bookingItemId: item.id,
        metafields: {
          ticketTypeId,
          attendeeName,
          attendeeDocument,
        },
      })
      .then(() => {
        getData()
        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Dados alterados com sucesso',
        })
        setEnable(true)
      })
      .catch((error) => {
        addToast({
          type: 'error',
          title: 'Erro ao logar',
          description: error?.response?.data?.message || 'Ocorreu um erro',
        })
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className="update_ticket">
      <div className="update_ticket_content">
        <TextField
          name="nome"
          placeholder="Nome"
          type="text"
          value={values?.attendeeName}
          onInputChange={(e) => {
            handleChange(e, 'attendeeName')
          }}
          disabled={!edit}
        />
        <TextField
          name="document"
          placeholder="Documento"
          type="text"
          value={maskCpf(values?.attendeeDocument)}
          onInputChange={(e) => {
            handleChange(e, 'attendeeDocument')
          }}
          disabled={!edit}
        />
        {loading ? (
          <div>
            <Loader size={20} />
          </div>
        ) : !enableSelect ? (
          <Button
            onClick={() => setEnableSelect(true)}
            disabled={!enable || disabled || !edit}
          >
            <div>{item?.ticketType?.slug}</div>
          </Button>
        ) : (
          <div className="custom-select inside right">
            <select
              name="paymentMethod"
              id="paymentMethod"
              onChange={(e) => handleChange(e.target.value, 'ticketTypeId')}
              value={values.ticketTypeId}
              disabled={!enable}
            >
              {item.allowedTicketTypes?.map((t: any) => (
                <option
                  value={t.id}
                  key={t?.id}
                  onClick={() => handleChange(t.id, 'ticketTypeId')}
                >
                  {t.name}
                </option>
              ))}
            </select>

            <span className="custom-arrow"></span>
          </div>
        )}
        {edit && (
          <Button onClick={handleSubmit} disabled={loading || disabled}>
            <div>{loading ? <Loader size={20} /> : 'salvar'}</div>
          </Button>
        )}
      </div>
    </div>
  )
}
