import { Navigate, useLocation } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth'

interface AuthContextProvider {
  children: JSX.Element
}

export function PrivateRoute({ children }: AuthContextProvider) {
  const auth = useAuth()
  const location = useLocation()

  const data = auth?.user || localStorage.getItem('user_cdigital_pdv')

  if (!data) {
    return <Navigate to="/login" state={{ from: location }} />
  }

  return children
}
