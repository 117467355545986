import { RouterProvider } from 'react-router-dom'
import router from './Router'
import { PlugHubProvider } from './@nanpos/plughub-react'
import { AuthProvider } from './hooks/useAuth'
import { ToastProvider } from './hooks/useToast'

function App() {
  return (
    <AuthProvider>
      <ToastProvider>
        <PlugHubProvider tenant={process.env.REACT_APP_IS_DEVELOP ? "cdigital-develop" : "cdigital"} bookingChannel="pdv">
          <RouterProvider router={router} />
        </PlugHubProvider>
      </ToastProvider>
    </AuthProvider>
  )
}

export default App
