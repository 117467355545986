import './paper.scss'

interface PropsBasePrint {
  children: React.ReactElement
}

export default function BasePrint({ children }: PropsBasePrint) {
  return (
    <div className="zebra landscape" id="print">
      {children}
    </div>
  )
}
