import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { instance } from '../@nanpos/plughub-react/apiclient'

export interface UserData {
  login?: string
  name?: string
  access: string
  refresh: string
}

interface AuthContextData {
  signIn: (
    username: FormDataEntryValue | null,
    password: FormDataEntryValue | null,
    callback: () => void,
  ) => Promise<void>
  signOut: () => void
  addLoading: (message: string) => void
  endLoading: () => void
  user: UserData | null
  formError: string
  loading: string
}

interface AuthContextProvider {
  children: React.ReactNode
}

const AuthContext = React.createContext<AuthContextData>({} as AuthContextData)

export function AuthProvider({ children }: AuthContextProvider) {
  const [user, setUser] = useState<UserData | null>(null)
  const [formError, setFormError] = useState<string>('')
  const [loading, setLoading] = useState<string>('')

  useEffect(() => {
    const data = localStorage.getItem('user_cdigital_pdv')

    if (data) {
      const auth = JSON.parse(data)

      setToken(auth.access)
      const status = {
        login: auth.login,
        name: auth.name,
        access: auth.access,
        refresh: auth.refresh,
      }
      setUser(status)
    }
  }, [])

  const addLoading = (message: string) => setLoading(message)
  const endLoading = () => setLoading('')

  const signIn = async (
    username: FormDataEntryValue | null,
    password: FormDataEntryValue | null,
    callback: () => void,
  ) => {
    addLoading('Autenticando ...')
    signOut()
    const data = {
      username,
      password,
    }

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_HOST}/auth/token/`,
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    }

    await axios
      .request(config)
      .then((response) => {
        const status = {
          login: username?.toString(),
          name: username?.toString(),
          access: response.data.access,
          refresh: response.data.refresh,
        }
        setToken(response.data.access)
        setUser(status)

        localStorage.setItem('user_cdigital_pdv', JSON.stringify(status))

        callback()
      })
      .catch((error) => {
        setFormError(error.response.data.detail || 'Ocorreu um erro.')
        endLoading()
      })
  }

  function setToken(token: string): void {
    instance.defaults.headers['Authorization'] = `Bearer ${token}`
  }

  const signOut = () => {
    localStorage.removeItem('user_cdigital_pdv')
    setFormError('')
    setUser(null)
    delete instance.defaults.headers.Authorization
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        formError,
        signIn,
        signOut,
        addLoading,
        endLoading,
        loading,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  return React.useContext(AuthContext)
}
