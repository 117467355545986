import { useState, KeyboardEvent } from 'react'
import Base from '../components/base'
import Button from '../components/Elements/Button'
import TextField from '../components/Elements/TextField'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { Loader } from '../components/Elements/Loader'
import { useAuth } from '../hooks/useAuth'
import SignUp from '../components/SignUp'
import { maskCpf } from '../utils/formatter'

export function Home() {
  const [value, setValue] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const { signOut } = useAuth()
  const [error, setError] = useState<string>('')
  const [isNotFound, setNotFound] = useState<boolean>(false)
  let navigate = useNavigate()

  const handleSearch = async () => {
    setLoading(true)
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url:
        `${process.env.REACT_APP_API_HOST}/customers/search/?cpf=` +
        value.replace(/[^\d]+/g, ''),
      headers: {
        Authorization: 'Token 9d6bea0502ac887a8d9c99e78c191e3a8c2e3978',
      },
    }

    //09670515416
    axios
      .request(config)
      .then((response) => {
        const { id } = response.data.customer
        navigate('/customers/' + id)
      })
      .catch((error) => {
        if (error.response.status === 401) {
          signOut()
        } else if (error.response.status === 404) {
          setError('Cliente não encontrado.')
          setNotFound(true)
        } else {
          const data = error.response.data
          console.log('datA:', data)
          setError(`Ocorreu um erro interno: ${data}`)
        }
      })
      .finally(() => setLoading(false))
  }

  const handleKeyboardEvent = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && value) {
      handleSearch()
    }
  }

  return (
    <Base>
      <div className="container">
        <div className="content">
          <div className="wrap">
            <h2>Informe o documento do cliente</h2>
            <br />
            <div className="grid">
              <div className="s3">
                <TextField
                  value={maskCpf(value)}
                  onInputChange={(e) => {
                    setValue(e)
                    setError('')
                  }}
                  onKeyDown={handleKeyboardEvent}
                />
              </div>
              <Button
                onClick={handleSearch}
                disabled={!value || loading || !!error}
              >
                <div>{loading ? <Loader size={20} /> : 'Pesquisar'}</div>
              </Button>
            </div>
          </div>
          {isNotFound ? (
            <>
              <div className="message message-warning">{error}</div>
              {isNotFound && (
                <SignUp
                  document={value}
                  setValue={setValue}
                  handleSearch={handleSearch}
                />
              )}
            </>
          ) : (
            <>
              {error && <div className="message message-warning">{error}</div>}
            </>
          )}
        </div>
      </div>
    </Base>
  )
}
