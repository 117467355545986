import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../../hooks/useAuth'
import Button from '../Elements/Button'
import TextField from '../Elements/TextField'
import './style.scss'
import { Loader } from '../Elements/Loader'
import { useEffect } from 'react'
import { useToast } from '../../hooks/useToast'

export default function Login() {
  const navigate = useNavigate()
  const location = useLocation()
  const { signIn, formError, loading } = useAuth()
  const { addToast } = useToast()
  const from = location.state?.from?.pathname || '/'

  useEffect(() => {
    if (formError) {
      addToast({
        type: 'error',
        title: 'Erro ao logar',
        description: formError,
      })
    }
  }, [addToast, formError])

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()

    const formData = new FormData(event.currentTarget)
    const login = formData.get('login')
    const password = formData.get('senha')

    signIn(login, password, () => {
      navigate(from, { replace: true })
    })
  }

  if (loading) {
    return (
      <div className="loading">
        <div className="item">
          <Loader /> {loading}
        </div>
      </div>
    )
  }

  return (
    <div className="login">
      <form onSubmit={handleSubmit}>
        <div className="login-container">
          <h3>Entre com seu login e senha</h3>
          <TextField name="login" placeholder="Email" type="text" />
          <TextField name="senha" placeholder="Senha" type="password" />
          <Button type="submit">Entrar</Button>
        </div>
      </form>
    </div>
  )
}
