import { Link, useNavigate } from 'react-router-dom'
import { useBookingCartContext } from '../../@nanpos/plughub-react/BookingCartContext'
import { formateReal } from '../../utils/formatter'
import Button from '../Elements/Button'
import { TbShoppingCartOff } from 'react-icons/tb'
import { useState } from 'react'

import './style.scss'
import Item from './item'

export default function Cart() {
  const {
    removeFromCart,
    total,
    totalQtde,
    clearCart,
    makeReservation,
    items,
    owner,
  } = useBookingCartContext()
  const [error, setError] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [pagamento, setPagamento] = useState<string>('')
  const [_, name, document] = owner.split('__')
  let navigate = useNavigate()

  const author = 'autor'

  const onSave = () => {
    // console.log('clicou')

    setLoading(true)
    makeReservation(author)
      .then((res) => {
        const bookingId = res.data.bookingId

        clearCart()
        navigate(`/booking/${bookingId}`)
      })
      .catch((err) => {
        setError('Ocorreu um erro ao reservar')
        clearCart()
        setLoading(false)
        setTimeout(() => setError(''), 2000)
      })
  }

  return (
    <div className="cart">
      {error && (
        <div className="cart-message">
          <div className="message message-error ">{error}</div>
        </div>
      )}
      <div className="cart-container">
        <div className="cart-header">
          <h4>{name}</h4>
          <h4>{document}</h4>
        </div>
        <div className="cart-content">
          {totalQtde === 0 && (
            <div className="empty">
              <TbShoppingCartOff size={100} />
              Vazio
            </div>
          )}
          {Object.entries(items).map(([key, value], i) => (
            <Item value={value} properts={key} key={i} />
          ))}
        </div>
        <div className="cart-footer">
          <div>
            <h4>Quantidade: {totalQtde}</h4>
            <h4>Total: {formateReal(total)}</h4>
          </div>
          <Button disabled={totalQtde === 0} onClick={onSave}>
            Prosseguir
          </Button>
        </div>
      </div>
    </div>
  )
}
