import { Link, useNavigate, useParams } from 'react-router-dom'
import { ItensGroups } from '../@nanpos/plughub-react'
import { GoChevronRight, GoChevronLeft } from 'react-icons/go'
import Base from '../components/base'
import Cart from '../components/cart'
import Action from '../components/Action'
import { useBookingCartContext } from '../@nanpos/plughub-react/BookingCartContext'
import { useEffect } from 'react'

export function Event() {
  const { items, owner } = useBookingCartContext()
  const [id] = owner.split('__')
  let navigate = useNavigate()
  useEffect(() => {
    Object.keys(items).map((key) => console.log(key.split('__')[2]))
  }, [items])

  const params = useParams()

  return (
    <Base>
      <div className="container">
        <div className="content">
          <div className="header">
            <div onClick={() => navigate(-1)}>
              <div className="icon">
                <GoChevronLeft size={34} />
              </div>
            </div>
            <h2>{params.eventId}</h2>
          </div>
          <div className="scroll">
            <ItensGroups
              repository={`${params.eventId}`}
              grouper="sector"
              subject={id}
              renderComponent={(props) => {
                return (
                  <>
                    <h3>Setores</h3>
                    <ol className="list">
                      {props.itensGroups?.map((item) => {
                        const opacity = Object.keys(items).some(
                          (key) => key?.split('__')[2] !== item.id,
                        )

                        return (
                          <li
                            key={item.id}
                            style={
                              opacity ? { opacity: '.5' } : { opacity: '1' }
                            }
                          >
                            {opacity ? (
                              <div className="list-item">
                                <span>{item.id}</span> - {item.metadata.name} -
                                R$
                                {item.metadata.amountBase}
                              </div>
                            ) : (
                              <>
                                {/* <Link to={`/${params.eventId}/${item.id}`}>
                                  <span>{item.id}</span> - {item.metadata.name}{' '}
                                  - R$
                                  {item.metadata.amountBase}
                                </Link> */}

                                <a>
                                  <span>{item.id}</span> - {item.metadata.name}{' '}
                                  - R$
                                  {item.metadata.amountBase}
                                </a>

                                <div className="action">
                                  {/* <Link
                                    to={`/${params.eventId}/${item.id}`}
                                    className="link"
                                  >
                                    Subsetores
                                  </Link> */}
                                  <Action item={item} />
                                </div>
                              </>
                            )}
                          </li>
                        )
                      })}
                    </ol>
                  </>
                )
              }}
            />
          </div>
        </div>
        <Cart />
      </div>
    </Base>
  )
}
