import React, { useEffect } from 'react'
import type { Repository, RepositoryList } from '../@nanpos/plughub-react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { RepoList } from '../@nanpos/plughub-react'
import { GoChevronLeft, GoChevronRight } from 'react-icons/go'
import Menu from '../components/menu'
import Cart from '../components/cart'
import { useBookingCartContext } from '../@nanpos/plughub-react/BookingCartContext'

type EventProps = {}

type EventRepository = RepositoryList<EventProps>

type EventListProps = {
  repositories: Repository<EventRepository>[]
  id: string
}

const EventList: React.FunctionComponent<EventListProps> = (props) => {
  return (
    <ol className="list">
      {props.repositories.map((repository) => {
        return (
          <li key={repository.id}>
            <Link to={`/${repository.id}`}>
              <div className="list-content">
                {repository.id}
                <GoChevronRight size={26} />
              </div>
            </Link>
          </li>
        )
      })}
    </ol>
  )
}

export function List() {
  let navigate = useNavigate()
  const { owner } = useBookingCartContext()
  const [id] = owner.split('__')
  return (
    <main>
      <Menu />

      <div className="container">
        <div className="content">
          <div className="header">
            <div onClick={() => navigate(-1)}>
              <div className="icon">
                <GoChevronLeft size={34} />
              </div>
            </div>
            <h2>Lista de eventos</h2>
          </div>
          <div>
            <RepoList
              repoType="event"
              grouper="campeonato"
              group="brasileiro"
              subject={id}
              as={EventList}
            />
            {/* <Button variant="contained"> Prosseguir</Button>
            <Button variant="contained" disabled>
              Disabled
            </Button>
            <Button variant="outlined">Loading</Button> */}
          </div>
        </div>
        <Cart />
      </div>
    </main>
  )
}
