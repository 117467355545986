import { useState, InputHTMLAttributes } from 'react'

import { VscEye, VscEyeClosed } from 'react-icons/vsc'

import './style.scss'

export type TextFieldProps = {
  onInputChange?: (value: string) => void
  label?: string
  initialValue?: string
  icon?: React.ReactNode
  iconPosition?: 'left' | 'right'
  disabled?: boolean
  error?: string
  type?: string
  password?: boolean
} & InputHTMLAttributes<HTMLInputElement>

const TextField = ({
  icon,
  iconPosition = 'left',
  label,
  name,
  initialValue = '',
  type = 'text',
  error,
  disabled = false,
  onInputChange,
  password = false,

  ...props
}: TextFieldProps) => {
  const [value, setValue] = useState(initialValue)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value
    setValue(newValue)

    !!onInputChange && onInputChange(newValue)
  }

  return (
    <div className="wrapper">
      {!!label && <label htmlFor={name}>{label}</label>}
      <div className="input">
        {!!icon && icon}

        <input
          type={type}
          onChange={onChange}
          value={value}
          disabled={disabled}
          name={name}
          {...(label ? { id: name } : {})}
          {...props}
        />
      </div>
      {!!error && <div className="error">{error}</div>}
    </div>
  )
}

export default TextField
