import { useEffect, useState } from 'react'
import Base from '../components/base'
import Button from '../components/Elements/Button'
import TextField from '../components/Elements/TextField'
import { instance } from '../@nanpos/plughub-react/apiclient'
import { useNavigate, useParams } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth'
import { formateReal, formatterDate } from '../utils/formatter'
import UpdateTicket from '../components/UpdateTicket'
import { useToast } from '../hooks/useToast'
// import { formateReal } from '../utils/formatter'

export function Booking() {
  const { bookingId } = useParams()
  const { signOut } = useAuth()
  const [paymentMethod, setPaymentMethod] = useState<string>('')
  let navigate = useNavigate()
  const { addToast } = useToast()
  const [error, setError] = useState<string>('')
  const [id, setId] = useState<any>()

  const [data, setData] = useState<any>()

  useEffect(() => {
    setError('')
    bookingId && getData()
    return () => {
      setData([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingId])

  const getData = async (id?: string) => {
    const param = id || bookingId
    setError('')
    const tenant = process.env.REACT_APP_IS_DEVELOP
      ? 'cdigital-develop'
      : 'cdigital'
    const url = `/Booking/GetBookingOrder?BookingId=${param}&Tenant=${tenant}`
    await instance
      .get(url)
      .then((response) => {
        setData(response.data)
        if (id) {
          navigate(`/booking/${id}`)
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          signOut()
        }

        setError(error?.response?.data?.message || 'Ocorreu um erro')
      })
  }

  const onConfirm = async () => {
    const url = `/Booking/ConfirmBookingOrder`
    const tenant = process.env.REACT_APP_IS_DEVELOP
      ? 'cdigital-develop'
      : 'cdigital'

    const data = {
      bookingId,
      tenant: tenant,
    }

    await instance
      .post(url, data)
      .then(() => {
        getData()
      })
      .catch((error) => {
        setError(error?.response?.data?.message || 'Ocorreu um erro')
        setTimeout(() => {
          setError('')
        }, 4000)
      })
  }

  const onPayment = async () => {
    const url = `/Booking/PayBookingOrder`
    const tenant = process.env.REACT_APP_IS_DEVELOP
      ? 'cdigital-develop'
      : 'cdigital'

    await instance
      .post(url, {
        bookingId,
        paymentMethod,
        tenant: tenant,
      })
      .then((response) => {
        getData()
        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Confirmado com sucesso',
        })
      })
      .catch((error) => {
        setError(error?.response?.data?.message || 'Ocorreu um erro')
      })
  }

  const onCancel = async () => {
    const url = `/Booking/CancelBookingOrder`
    const tenant = process.env.REACT_APP_IS_DEVELOP
      ? 'cdigital-develop'
      : 'cdigital'

    await instance
      .post(url, {
        bookingId,
        tenant: tenant,
      })
      .then(() => {
        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Cancelado com sucesso',
        })

        getData()
      })
      .catch((error) => {
        setError(error?.response?.data?.message || 'Ocorreu um erro')
        setTimeout(() => {
          setError('')
        }, 4000)
      })
  }

  const handleKeyboardEvent = (e: any) => {
    if (e.key === 'Enter' && id) {
      navigate(`/booking/${id}`)
    }
  }

  return (
    <Base>
      <div className="container container_booking">
        {bookingId ? (
          <div className="content">
            <h2>Pedido {bookingId}</h2>
            <p>{data?.bookingStatus}</p>
            <br />
            {error && <div className="message message-error">{error}</div>}{' '}
            <section className="cards scroll">
              {data?.bookingItems?.map((item: any, i: number) => {
                return (
                  <article key={i}>
                    <div className="sector">
                      <span className="border">#{item.id}</span>

                      <h3>{item.sector.name}</h3>

                      <span>Bloco: {item.subsector.name}</span>
                      <span>Portão: {item.gate.name}</span>
                      <span>Fileira: {item.seat.row}</span>
                      <span>Cadeira: {item.seat.number}</span>
                    </div>
                    <div className="ticket">
                      <div className="item">
                        <h3>{item.event.name}</h3>
                      </div>
                      <div className="item small">
                        <div>{item.event.tournament}</div>
                        <div>{formatterDate(item.event.startsAt)}</div>
                      </div>
                      <div className="item">{item.event.venue.name}</div>
                      <div className="item thin">
                        <div>{item.event.insurancePolicy.company}</div>
                        <div>{item.event.insurancePolicy.policy}</div>
                        <div>{item.event.venue.documents.alvara.nome}: </div>
                        <div>{item.event.venue.documents.alvara.valor} </div>
                        <div>
                          v.{item.event.venue.documents.alvara.validade}{' '}
                        </div>{' '}
                        -<div>{item.event.venue.documents.avcb.nome}: </div>
                        <div>{item.event.venue.documents.avcb.valor} </div> -
                        <div>v{item.event.venue.documents.avcb.validade} </div>
                      </div>

                      <div className="item">
                        <span className="border">
                          {formateReal(item.amount)}
                        </span>
                      </div>

                      {item.ticketType && (
                        <UpdateTicket
                          item={item}
                          bookingId={bookingId}
                          getData={getData}
                          status={data?.bookingStatus}
                        />
                      )}
                    </div>
                  </article>
                )
              })}
            </section>
            <footer>
              {(data?.bookingStatus === 'reserved' ||
                data?.bookingStatus === 'filled') && (
                <Button onClick={onCancel}>Cancelar</Button>
              )}

              {data?.bookingStatus === 'filled' && (
                <div className="custom-select">
                  <select
                    name="paymentMethod"
                    id="paymentMethod"
                    onChange={(e) => setPaymentMethod(e.target.value)}
                  >
                    <option>Forma de pagamento</option>
                    <option value="CREDIT_CARD">Cartão Crédito</option>
                    <option value="DEBIT_CARD">Cartão débito</option>
                    <option value="MONEY">Dinheiro</option>
                    <option value="PIX">Pix</option>
                  </select>
                  <span className="custom-arrow"></span>
                </div>
              )}

              {paymentMethod && data?.bookingStatus === 'filled' && (
                <Button onClick={onPayment}>Realizar pagamento</Button>
              )}

              {data?.bookingStatus === 'confirmed' && (
                <Button
                  onClick={() =>
                    window.open(
                      `/booking/${bookingId}/print`,
                      '_blank',
                      'rel=noopener noreferrer',
                    )
                  }
                >
                  Imprimir
                </Button>
              )}

              {data?.bookingStatus === 'reserved' && (
                <Button onClick={onConfirm}>Confirmar</Button>
              )}
            </footer>
          </div>
        ) : (
          <div className="content">
            <div className="wrap">
              {error && (
                <div
                  className="message message-error"
                  style={{ marginBottom: '20px' }}
                >
                  {error}
                </div>
              )}
              <h2>Informe o número da reserva</h2>
              <br />

              <div className="grid">
                <div className="s3">
                  <TextField
                    onInputChange={(e) => setId(e)}
                    onKeyDown={handleKeyboardEvent}
                  />
                </div>
                <Button
                  onClick={() => {
                    getData(id)
                  }}
                >
                  Pesquisar
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </Base>
  )
}
