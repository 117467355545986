export const maskCpf = (value: string) => {
  return value
    ?.replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

export const maskPhone = (value: string) => {
  return value
    ?.replace(/\D/g, '')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
    .replace(/(-\d{4})\d+?$/, '$1')
}

export const maskCard = (value: string | undefined) => {
  return value
    ?.replace(/\D/g, '')
    .replace(/(\d{4})(\d)/, '$1 $2')
    .replace(/(\d{4})(\d)/, '$1 $2')
    .replace(/(\d{4})(\d{1,2})/, '$1 $2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

export const maskCartValidate = (value: string | undefined) => {
  return value
    ?.replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

export const maskDate = (value: string) => {
  return value
    ?.replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{4})\d+?$/, '$1')
}

export const formateReal = (value?: string | number) => {
  const num = new Number(value)
  return num?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
}


export const formatterDate = (value: string): string => {
  const d = new Date(Date.parse(value))

  var output = d.toLocaleString('pt-BR', {
    hour12: false,
    dateStyle: 'short',
    timeStyle: 'short'
  });
  var outputFormatted = output.replace(/^(\d{2})\/(\d{2})\/(\d{4}), (\d{2}):(\d{2})/, '$1/$2/$3 $4:$5');

  return outputFormatted
}

