import { Link, useParams } from 'react-router-dom'
import { RepoItensList } from '../@nanpos/plughub-react'
import Base from '../components/base'
import Cart from '../components/cart'
import { GoChevronRight, GoChevronLeft } from 'react-icons/go'

export function Seats() {
  const params = useParams()

  return (
    <Base>
      <div className="container">
        <div className="content">
          <div className="header">
            <Link to={`/${params.eventId}/${params.sectorId}`}>
              <div className="icon">
                <GoChevronLeft size={34} />
              </div>
            </Link>
            <h2>{params.eventId}</h2>
          </div>

          <div className="scroll">
            <RepoItensList
              repository={`${params.eventId}`}
              grouper="subsector"
              group={`${params.subsectorId}`}
              renderComponent={(props) => {
                return (
                  <>
                    <p>Cadeiras:</p>
                    <ul>
                      {props.items?.map((item) => (
                        <li>
                          <code>{item.id}</code> - {item.metadata.rowNumber} -
                          Status da Reserva: {item.bookingStatus}
                        </li>
                      ))}
                    </ul>
                    <hr />
                  </>
                )
              }}
            />
          </div>
        </div>
        <Cart />
      </div>
    </Base>
  )
}
